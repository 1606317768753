import React from "react"
import { FaTwitterSquare, FaYoutubeSquare, FaLinkedin, FaFacebookSquare } from 'react-icons/fa';

const SocialIcons = () => (
    <>
        <a href="https://www.youtube.com/guidingdigital" target="_blank" rel="noreferrer" aria-label="YouTube">
            <FaYoutubeSquare className="socialIcon" />
        </a>
        <a href="https://twitter.com/GuidingDigital" target="_blank" rel="noreferrer" aria-label="Twitter">
            <FaTwitterSquare className="socialIcon" />
        </a>
        <a href="https://www.linkedin.com/company/guidingdigital/" target="_blank" rel="noreferrer" aria-label="LinkedIn">
            <FaLinkedin className="socialIcon" />
        </a> 
        <a href="https://www.facebook.com/GuidingDigital/" target="_blank" rel="noreferrer" aria-label="Facebook">
            <FaFacebookSquare className="socialIcon" />
        </a>
    </>
)

export default SocialIcons
import React from "react"
import PropTypes from "prop-types"
import Header from "./Header"
import Footer from "./Footer"
import Seo from "../Seo"
import "./Layout.css"

const Layout = ({ children, title, description, schemaMarkup, metaUrl, 
  twitterCardType, twitterImage }) => {
  return (
    <>
      <Seo
        title={title}
        description={description}
        schemaMarkup={schemaMarkup}
        metaUrl={metaUrl}
        twitterCardType={twitterCardType}
        twitterImage={twitterImage}
      />
      <Header />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
import React from "react"
import {Navbar, Nav, Container } from 'react-bootstrap'
import SocialIcons from "./SocialIcons"
import { StaticImage } from 'gatsby-plugin-image'
import "./Header.scss"

const Header = () => {
  return (
    <Navbar expand="md" fixed="top">
          <Navbar.Brand href="/">
            <StaticImage
              src="../../images/guiding-digital.png"
              alt="Guiding Digital"
              layout="fixed"
              width={215}
              placeholder="none" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/services/">Services</Nav.Link>
              <Nav.Link href="/about/">About</Nav.Link>
              <Nav.Link href="/blog/">Blog</Nav.Link>
              <Nav.Link href="/contact/">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Container className="social d-none d-lg-inline text-right">
            <SocialIcons />   
          </Container>
    </Navbar>
    )
  }

  export default Header
import React from "react"
import { Link } from 'gatsby'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import SocialIcons from "./SocialIcons"
import { StaticImage } from 'gatsby-plugin-image'
import "./Footer.scss"

const Footer = () => {
    return (
    <footer className="footer">
        <Container>
            <Row>
                <Col xs={12} md={6} lg={3} className="mb-5">
                    <StaticImage
                        src="../../images/guiding-digital.png"
                        alt="Guiding Digital"
                        layout="fixed"
                        width={215}
                        placeholder="blurred" />
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-5">
                    <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link href="/services/">Services</Nav.Link>
                        <Nav.Link href="/about/">About</Nav.Link>
                        <Nav.Link href="/blog/">Blog</Nav.Link>
                        <Nav.Link href="/contact/">Contact Us</Nav.Link>
                    </Nav>
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-5">
                    <a href="tel:4797092520">(479) 709-2520</a> <br />
                    <a href="mailto:contact@guidingdigital.com">contact@guidingdigital.com</a><br /><br />

                    PO Box 8053<br />
                    Springdale, AR 72766
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <span className="getInTouch">Get in touch!</span>
                    <form id="contactForm" action="/contact/" method="get" className="needs-validation">
                        <input className="form-control form-control-lg d-block d-sm-inline" type="email" 
                            name="email" id="footer-email" placeholder="Enter Email" 
                            required aria-label="Email" />
                        <input type="submit" value="Contact Us" className="btn btn-primary" />
                    </form>        
                </Col>
            </Row>
            <Row className="text-center">  
                <Col>
                    <SocialIcons />            
                </Col>
            </Row>
            <Row className="text-center">
                <Col className="copyright">
                    © Copyright 2021 Guiding Digital LLC.  All rights reserved. | <Link to="/privacy-policy/">Privacy Policy</Link> | <Link to="/terms-and-conditions/">Terms &amp; Conditions</Link>
                </Col>
            </Row>
        </Container>
    </footer>
    )
}

export default Footer
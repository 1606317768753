import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, schemaMarkup, metaUrl, twitterCardType, twitterImage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const twitterCard = twitterCardType || "summary"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
    >
      <link rel="preconnect" href="https://www.googletagmanager.com"></link>
      <link rel="preconnect" href="https://www.google-analytics.com"></link>
      <meta name="description" content={metaDescription}></meta>
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={metaDescription}></meta>
      <meta property="og:type" content="website"></meta>
      <meta name="twitter:card" content={twitterCard}></meta>
      <meta name="twitter:creator" content={site.siteMetadata.author}></meta>
      <meta name="twitter:title" content={title}></meta>
      <meta name="twitter:description" content={metaDescription}></meta>
      {metaUrl &&
        <meta property="og:url" content={metaUrl}></meta>
      }
      <meta name="twitter:site" content={site.siteMetadata.author}></meta>
      {twitterImage &&
        <meta name="twitter:image" content={twitterImage}></meta>
      }
      {twitterImage &&
        <meta property="og:image" content={twitterImage}></meta>
      }
      {schemaMarkup &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      }
      <script async type="text/javascript" src="/scripts/modernizr-custom.js" />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  schemaMarkup: PropTypes.object,
}

export default Seo
